import { Table, Box, SpaceBetween, Header, Link, StatusIndicator } from '@amzn/awsui-components-react/polaris';
import React, { useMemo, useState } from 'react';
import { OpenCalculationReview, OpenCalculationReviewsResult } from 'src/models/dashboard/OpenCalculationReview';
import EntityStatusUtil from 'src/services/common/EntityStatusUtil';
import TaskStatusIndicator from '../shared/TaskStatusIndicator';
import CONSTANTS from 'src/utils/constants';
import TPENavigator from '../TPENavigator';
import DASHBOARD_CONSTANTS from './dashboardConstants';

export default function OpenCalculationReviewsGrid(props: { openCalculationReviewsResult: OpenCalculationReviewsResult, isLoading: boolean }) {
  const { openCalculationReviewsResult, isLoading } = props;

  const [navigateURL, setNavigateURL] = useState('');

  const navigateToCalcBuilder = (calculationNumber: string, recordId: string) => {
    if (recordId != null) {
        setNavigateURL(CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.URL + '/' + btoa(recordId));
    } else {
        setNavigateURL(CONSTANTS.PAGE_NAV.ICRS_CALCULATION_BUILDER.URL + '/' + btoa(calculationNumber));
    }
  }

  const colDefs: any[] = useMemo(() => [
      {
          id: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.WORKBOOK_NAME.ACCESSOR,
          header: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.WORKBOOK_NAME.HEADER,
          cell: (item: OpenCalculationReview) => item.workbookName,
          width: '20%'
      },
      {
          id: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.CALCULATION_NUMBER.ACCESSOR,
          header: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.CALCULATION_NUMBER.HEADER,
          cell: (item: OpenCalculationReview) => <Link href="#" onFollow={() => navigateToCalcBuilder(item.calculationNumber, item.recordId)}>{item.calculationNumber}</Link>,
          width: '15%'
      },
      {
          id: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.ATP_CALCULATION_STATUS.ACCESSOR,
          header: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.ATP_CALCULATION_STATUS.HEADER,
          cell: (item: OpenCalculationReview) => <StatusIndicator type={EntityStatusUtil.mapToStatusIndicatorBy(item.atpCalculationStatus)}>{item.atpCalculationStatus}</StatusIndicator>,
          width: '15%'
      },
      {
          id: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.APTTUS_CALCULATION_STATUS.ACCESSOR,
          header: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.APTTUS_CALCULATION_STATUS.HEADER,
          cell: (item: OpenCalculationReview) => <StatusIndicator type={EntityStatusUtil.mapToStatusIndicatorBy(item.apttusCalculationStatus)}>{item.apttusCalculationStatus}</StatusIndicator>,
          width: '15%'
      },
      {
          id: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.TASK_STATUS.ACCESSOR,
          header: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.TASK_STATUS.HEADER,
          cell: (item: OpenCalculationReview) => <TaskStatusIndicator status={item.taskStatus} />,
          width: '10%'
      },
      {
          id: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.DUE_DATE.ACCESSOR,
          header: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.DUE_DATE.HEADER,
          cell: (item: OpenCalculationReview) => item.dueDateDisplay,
          width: '12%'
      },
      {
          id: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.OWNER.ACCESSOR,
          header: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.OWNER.HEADER,
          cell: (item: OpenCalculationReview) => item.owner,
          width: '8%'
      },
      {
          id: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.COMMENTS.ACCESSOR,
          header: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.COMMENTS.HEADER,
          cell: () => '',
          width: '5%'
      }
  ], [openCalculationReviewsResult]);


  return (
    <>
    <TPENavigator path={navigateURL} />
    <Table
        columnDefinitions={colDefs}
        enableKeyboardNavigation
        wrapLines={true}
        items={openCalculationReviewsResult?.items || []}
        loadingText="Loading"
        loading={isLoading}
        empty={
            <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
            >
            <SpaceBetween size="m">
                <b>No open CLI reviews</b>
                <span>All CLI reviews have been completed</span>
            </SpaceBetween>
            </Box>
        }
        header={
            <Header
            counter={isLoading ? '...' : `(${openCalculationReviewsResult?.totalSize || '0'})`}
            description="CLIs assigned to me that have status change from last MEC in ATP"
            >
            Open CLI review
            </Header>
        }
        footer={
            <Box textAlign="center">
              <Link href="#">View all CLI reviews</Link>
            </Box>
          }
      />
      </>
    )
}