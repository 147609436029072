import React, { useEffect, useContext } from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { initialState } from 'src/models/dashboard/DashboardState';
import { dashboardReducer, ACTIONS } from 'src/services/dashboard/DashboardReducer';
import './Dashboard.scss';
import { GlobalAppContext } from 'src/components/App';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import DashboardTiles from './DashboardTiles';
import OpenCalculationReviewsGrid from './OpenCalculationReviewsGrid';
import { getDefaultTopSearchPayload } from './OpenCalcReviewsSearchHelper';


export default function DashboardView() {
    const [state, dispatch] = useReducerWithLogger(dashboardReducer, initialState);
    const { userProfile, services } = useContext(GlobalAppContext);

    const { searchOpenCalcReviewsPayload } = state;

    const [openCalcReviewsSearchResult, isSearchingOpenCalcReviews, searchOpenCalcReviewsError] = services.dashboardService.searchOpenCalculationReviews(searchOpenCalcReviewsPayload);

    useEffect(() => {
        dispatch(ACTIONS.SET_OPEN_CALC_REVIEWS_PAYLOAD.withPayload(getDefaultTopSearchPayload()))
    }, [])

    return (
        <div style={{ marginLeft: '50px', marginRight:'50px' }}>
            <div className="welcomeUser">Welcome {userProfile.firstName}!</div>
            <SpaceBetween direction="vertical" size="l">
                <OpenCalculationReviewsGrid openCalculationReviewsResult={openCalcReviewsSearchResult} isLoading={isSearchingOpenCalcReviews}/>
                <DashboardTiles />
            </SpaceBetween>
        </div>
    );
}