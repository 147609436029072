import { OpenCalcReviewsSearchRequest } from "./OpenCalculationReview";

export interface DashboardState {
    isLoading: Boolean,
    error: string,
    searchOpenCalcReviewsPayload?: OpenCalcReviewsSearchRequest
}

export const initialState: DashboardState = {
    isLoading: true,
    error: '',
};