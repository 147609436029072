import { useEffect, useState } from 'react';
import { OpenCalculationReviewsResult, OpenCalcReviewsSearchRequest } from 'src/models/dashboard/OpenCalculationReview';

import apiService from '../ApiCallService';
import ErrorUtils from 'src/utils/ErrorUtils';
import DateUtils from 'src/utils/dateUtils';

/**
 * This class should only contain the API calls used in the dashboard
 */
export default class DashboardService {

    searchOpenCalculationReviews(payload: OpenCalcReviewsSearchRequest | undefined) : [OpenCalculationReviewsResult, boolean, string] {
        const [results, setResults] = useState(null as unknown as OpenCalculationReviewsResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.searchOpenCalculationReviews(payload);
                    const json : OpenCalculationReviewsResult = await response.json();
                    if (json.items != null) {
                        json.items.forEach(i => i.dueDateDisplay = DateUtils.formatDate(i.dueDate, 'YYYY-MM-DD'));
                    }
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }

            if (payload != null) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }
}
