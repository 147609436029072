const DASHBOARD_CONSTANTS = Object.freeze({
    TOP_N: 5,
    OPEN_CALCULATION_REVIEW_FIELDS: {
        WORKBOOK_NAME: {
            ACCESSOR: 'workbookName',
            HEADER: 'CWB',
            DB_FIELD: ''
        },
        CALCULATION_NUMBER: {
            ACCESSOR: 'calculationNumber',
            HEADER: 'CLI',
            DB_FIELD: 'entity_id'
        },
        ATP_CALCULATION_STATUS: {
            ACCESSOR: 'atpCalculationStatus',
            HEADER: 'ATP CLI status',
            DB_FIELD: ''
        },
        APTTUS_CALCULATION_STATUS: {
            ACCESSOR: 'apttusCalculationStatus',
            HEADER: 'Apttus CLI status',
            DB_FIELD: ''
        },
        TASK_STATUS: {
            ACCESSOR: 'taskStatus',
            HEADER: 'Status',
            DB_FIELD: ''
        },
        DUE_DATE: {
            ACCESSOR: 'dueDateDisplay',
            HEADER: 'Due date',
            DB_FIELD: 'due_date'
        },
        OWNER: {
            ACCESSOR: 'owner',
            HEADER: 'Owner',
            DB_FIELD: 'recipient'
        },
        COMMENTS: {
            ACCESSOR: 'comments',
            HEADER: 'Comment',
            DB_FIELD: ''
        },
    }
});

export default DASHBOARD_CONSTANTS;