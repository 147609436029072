
import TPEAction from 'src/models/common/TPEAction';
import { DashboardState } from 'src/models/dashboard/DashboardState'

/**
 * List here the actions supported by this reducer
 */
export const ACTIONS = Object.freeze({
  SET_OPEN_CALC_REVIEWS_PAYLOAD: new TPEAction('SET_OPEN_CALC_REVIEWS_PAYLOAD'),
});


/**
 * This function is responsible for updating the state based on action type
 * @param state The current dashboard state
 * @param action The current dispatched action
 */
export function dashboardReducer(state: DashboardState, action: TPEAction) {
  switch (action.type) {
    case ACTIONS.SET_OPEN_CALC_REVIEWS_PAYLOAD.type: {
      return {
        ...state,
        searchOpenCalcReviewsPayload: action.payload
      }
    }
    default:
      return state;
  }
}